<template>
    <div class="base-wishes">

        <div class="base-wishes__field">
            <input
                v-model="inputValue"
                type="text"
                class="base-wishes__input"
                :placeholder="placeholder"
                @keyup.enter="addChip(inputValue)"
                @input="onInput" />
            <v-icon
                v-if="showInputIcon"
                color="red"
                size="32"
                @click="addChip(inputValue)">
                mdi-plus-circle
            </v-icon>
        </div>

        <div class="base-wishes__chips">
            <v-chip
                v-for="chip in value"
                :key="chip"
                class="mr-2 my-1"
            >
                <!-- <v-icon :color="isWishes ? '#219653' : 'red'" class="mr-1">
                    {{ isWishes ? 'mdi-check' : 'mdi-close' }}
                </v-icon> -->
                {{ chip }}
                <img src="../../assets/PlusGrey.svg" class="base-wishes__chips__close" @click="removeChip(chip)" />
            </v-chip>

            <v-chip
                v-for="chip in filteredItems"
                :key="chip"
                outlined
                class="mr-2 my-1"
                @click="addExampleChip(chip)">
                {{ chip }}
            </v-chip>
            <div v-if="value.length !== 0" class="base-wishes__chips__space"></div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'BaseWishes',
        model: {
            prop: 'value',
            event: 'change',
        },
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            items: {
                type: Array,
                default: () => [],
            },
            isWishes: {
                type: Boolean,
            },
            placeholder: {
                type: String,
                default: '',
            },
        },
        data: () => ({
            inputValue: '',
            showInputIcon: false,
        }),
        computed: {
            filteredItems() {
                return _.difference(this.items, this.value);
            },
        },
        methods: {
            onInput() {
                this.showInputIcon = !!this.inputValue;
            },
            addChip(chipToAdd) {
                chipToAdd = chipToAdd.split(',');
                chipToAdd = _.uniq(chipToAdd.map(chip => chip.trim())); // trim and uniq
                chipToAdd = chipToAdd.filter(chip => !!chip); // remove empty items
                chipToAdd = chipToAdd.filter(
                    chip => this.value.indexOf(chip) === -1
                );

                this.$emit('change', [...this.value, ...chipToAdd]);
                this.inputValue = '';
                this.showInputIcon = false;
            },
            addExampleChip(chipToAdd) {
                if (chipToAdd && this.value.indexOf(chipToAdd) === -1) {
                    this.$emit('change', [...this.value, chipToAdd]);
                }
            },
            removeChip(chipToRemove) {
                const chips = this.value.filter(chip => chip !== chipToRemove);
                this.$emit('change', chips);
            },
        },
    };
</script>

<style lang="scss">
.base-wishes {
    display: flex;
    flex-direction: column;
    width: 100%;
    .v-chip {
        padding: 0;
    }

    &__chips {
        // margin-bottom: 24px;
        overflow: hidden;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #A2A5AE;
        &__space {
            margin-bottom: 24px;
        }
        > span {
            height: auto !important;
            min-height: 26px;
        }

        .v-chip__content {
            padding: 4px 16px;
            line-height: 16px;
            white-space: normal;
            height: auto !important;
            min-height: 26px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #A2A5AE;
        }
        .theme--light.v-chip:not(.v-chip--active) {
            background: transparent;
            border: 1px solid #D5D5D5;
            box-sizing: border-box;
            border-radius: 30px;
        }
        &__close {
            transform: rotate(45deg);
            width: 12px;
            height: 12px;
            margin-left: 2px;
        }
    }

    .base-wishes__field {
        min-height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 8px 12px;
    }

    .base-wishes__examples {
        min-height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 8px 0;

        > span {
            color: #4a4b50 !important;
        }
    }

    .base-wishes__input {
        border: none;
        outline: none;
        padding: 0 5px;
        flex: 1;
        width: 20px;
        display: block;
        font-family: IBM Plex Sans, Arial, sans-serif;
        font-size: 14px;
    }
}
</style>
